import React, { Component } from "react";
import Breadcrumb from "../Blogs/Breadcrumb";
import FooterSection from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";
import ContactForm from "./ContactForm";

class ContactPage extends Component {
  state = {
    data: {},
    iconList: [],
  };

  render() {
    return (
      <div className="contact-page">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header inverse={true} imageData={"/img/logo-white.png"} />
          <Breadcrumb
            height="sm"
            title="Contact Us"
            subHeading="Use the form below to send us a message, we'll be sure to respond quickly."
            blogBg="/img/firemen.jpg"
          />
          <section id="contact" className="contact-area ptb_50">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 pt-4 pt-md-0">
                  <ContactForm />
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default ContactPage;
