import React, { Component } from "react";
import Breadcrumb from "../Blogs/Breadcrumb";
import FooterSection from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";

class WebinarContactPage extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <div className="contact-page">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header inverse={true} imageData={"/img/logo-white.png"} />
          <Breadcrumb
            height="sm"
            title="Live Webinar / Lunch-n-Learn"
            subHeading="Wednesday, May 18th @ 11am PST."
          />
          <section id="contact" className="contact-area ptb_50">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 pt-4 pt-md-0">
                  <div className="text-center mb-2">
                    <strong style={{ fontSize: "1.2rem" }}>
                      First 25 Registrants get Doordash giftcard!*
                    </strong>
                  </div>
                  <div className="text-center mb-2">
                    Join our live webinar where we will showcase the features
                    and functionalities of LastRecord. Learn how
                    our systems are helping <strong>EMS & Fire Agencies</strong>{" "}
                    all across North America streamline their paper{" "}
                    <strong>
                      Performance Reviews, Task Books & Competencies!
                    </strong>
                  </div>
                  <div
                    className="text-center mb-2"
                    style={{ fontSize: "1.2rem" }}
                  >
                    Hosted By:
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="text-center mb-5">
                      <div>
                        <img
                          src="https://ik.imagekit.io/njdssfibtl9/casey.jfif"
                          style={{ height: 200, borderRadius: 10 }}
                        />
                      </div>
                      <strong>Casey McIntosh, Founder</strong>
                    </div>
                    <div className="text-center mb-5 ml-5">
                      <div>
                        <img
                          src="https://ik.imagekit.io/njdssfibtl9/vanessa.jfif"
                          style={{ height: 200, borderRadius: 10 }}
                        />
                      </div>
                      <strong>Vanessa Alyfantis, Director of Sales</strong>
                    </div>
                  </div>
                  {/*
                  <div
                    className="text-center mb-2"
                    style={{ fontSize: "1.2rem" }}
                  >
                    Register here:
                  </div>
                  <div className="contact-box text-center">
                    <form
                      method="POST"
                      name="webinar"
                      data-netlify="true"
                      className="contact-form"
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Your Name"
                              required="required"
                              onChange={this.changeHandler}
                              value={this.state.name}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              required="required"
                              onChange={this.changeHandler}
                              value={this.state.email}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="orgname"
                              placeholder="Organization Name"
                              required="required"
                              onChange={this.changeHandler}
                              value={this.state.organization}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-lg btn-block mt-3"
                          >
                            Register!
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="text-center mt-3">
                      <span style={{ fontSize: ".8rem" }}>
                        *20$ Doordash gift card, limited to 1 person per agency. Must attend webinar.
                      </span>
                    </div>
                  </div>
    */}
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default WebinarContactPage;
