import React, { Component } from 'react';
import Footer from './FooterSection/Footer';
import Header from './HeaderSection/Header';

class Trial extends Component {
    state = {
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="homepage-3 inner inner-pages" style={{ background: "#ededed" }}>
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <section id="home" className="section h-100vh overflow-hidden">
                        <div className="container h-100">
                            <div className="row align-items-center justify-content-center h-100">
                                <div className="col-12 col-md-7">
                                    <div className="welcome-intro text-center">
                                        <h1 className="text-dark">Create Free Account</h1>
                                        <p className="text-dark my-4">LastRecord is free to try, and we offer an instant sign up process. No talking to sales-people, no negotiating contracts.</p>
                                        <p className="text-dark my-4">To create a free account, simply click the button below and then the 'Try it Free' button.</p>

                                        <a href="https://app.lastrecord.com"><button className="btn btn-bordered" style={{ background: '#2879bf'}}>Create Account</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />

            </div>
        );
    }
}

export default Trial;