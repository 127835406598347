import React, { Component } from "react";
import BrandingSection from "../components/BrandingSection/Branding";
import DiscoverSection from "../components/DiscoverSection/DiscoverSix";
import Download from "../components/DownloadSection/Download";
import FaqSection from "../components/FaqSection/FaqOne";
import FooterSection from "../components/FooterSection/Footer";
import Header from "../components/HeaderSection/Header";
import HeroSection from "../components/HeroSection/HeroSix";
import PricingSection from "../components/PricingSection/PricingOne";

class ThemeSix extends Component {
  render() {
    return (
      <div className="homepage-6">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-white.png"} />
          <BrandingSection />
          <DiscoverSection />
          <PricingSection />
          <FaqSection />
          <Download />
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default ThemeSix;
