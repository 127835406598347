import React, { Component } from 'react';
import Footer from '../FooterSection/Footer';
import Header from '../HeaderSection/Header';
import Breadcrumb from './Breadcrumb';

class SkillEvals extends Component {
    render() {
        return (
            <div className="blog">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header inverse={true} imageData={"/img/logo-white.png"} />
                    <Breadcrumb title="Digital Skill Competencies" subHeading="Moving your Competencies and Skill Assessments to a cloud hosted software solution provides many benefits for your Organization." blogBg="/img/firemen.jpg" />
                    <section id="blog" className="section blog-area ptb_50">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div>
                                        {/* Single Blog Details */}
                                        <article className="single-blog-details">
                                            {/* Blog Thumb */}
                                            <div className="blog-thumb">
                                                <div style={{ maxWidth: 950, margin: 'auto' }}>
                                                    <video loop autoPlay muted id="background-video" style={{ width: '100%', border: 'solid 1px #e2e2e2', borderRadius: 10 }}>
                                                        <source src='/img/skillassessment.mp4' type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                            {/* Blog Content */}
                                            <div className="blog-content sApp-blog">
                                                {/* Meta Info */}
                                                <div className="meta-info d-flex flex-wrap align-items-center py-2">
                                                    <ul>
                                                        <li className="d-inline-block p-2">By Casey McIntosh</li>
                                                        <li className="d-inline-block p-2">August 3, 2021</li>
                                                    </ul>
                                                </div>
                                                {/* Blog Details */}
                                                <div className="blog-details">
                                                    <h1 className="blog-title py-3" style={{ fontSize: '1.2rem' }}>LastRecord provides a cloud hosted platform for Fire / EMS agency Skill Competencies and Assessments.</h1>
                                                    <p className="d-sm-block mt_20">Competencies (or Skill Assessments) have long been used in the Emergency Services to assess the abilities of first responders to perform the tasks needed to effectively do their jobs.</p>
                                                    <p className="d-sm-block mt_20">Traditionally, these assessments were done either on the drill ground or in the station - using paper and pencil. An employee would be given a task, or set of tasks, and then evaluated in real-time by an evaluator; checking boxes with either a pass or a fail as they progress through the evaluation. Most often, these assessment are graded on a simple pass or fail, however on occasion, Organizations will use a more complex scoring matrix including points, verbal responses, multiple choice, etc. </p>
                                                    <p className="d-sm-block mt_20">These paper processes, while effective and simple - are not the best choice in todays modern world. Moving to a digital approach, using web connected tablets or mobile phones, provides a much more efficient and reliable means of assessing employees. </p>
                                                    <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                                                        <p className="d-sm-block">Moving to a digital approach, using web connected tablets or mobile phones, provides a much more efficient and reliable means of assessing employees.</p>
                                                    </blockquote>
                                                    <p className="d-sm-block mt_20">Often times, after an employee is assessed on a skill sheet - the completed skill sheet is tucked away in a binder, filing cabinet - or worse, thrown away. This can cause a nightmare scenario in the event an employee is terminated down the road for 'poor performance'. The employee, and their Counsel, will likely immediately request copies of the employees Skill Assessments as evidence. Not having readily available access to this valuable evidentiary data, is a sure fire way to lose a labor dispute.</p>
                                                    <p className="d-sm-block mt_20">By using a cloud hosted, digital platform for your employee Skill Assessments and practical evaluations ensures that the data is always available, stored safely, and there when you need it. Having immediate access to trending data on past performance, video / audio evidence of the tasks being complete, and verified employee signatures will ensure your Agency is already prepared for the worse case scenario, if the need arises.</p>
                                                    <div className="blog-thumb">
                                                        <img src="/img/assessment.png" style={{ marginTop: 20 }} alt="" className="blog-featured-image" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Blog Comments */}
                                            <div className="blog-comments">
                                                {/* Admin */}
                                                <div className="admin media py-4 mt-4">
                                                    {/* Admin Thumb */}
                                                    <div className="admin-thumb avatar-lg">
                                                        <img className="rounded-circle" src="/img/casey.png" alt="" />
                                                    </div>
                                                    {/* Admin Content */}
                                                    <div className="admin-content media-body pl-3 pl-sm-4">
                                                        <h4 className="admin-name mb-2"><a href="/#">Casey McIntosh</a></h4>
                                                        <p>Casey was a Career Firefighter in Oregon from 2002 - 2016. He was also the founder of CrewSense.com and is a passionate supporter of the Emergency Services. He is now the founder of LastRecord.com.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default SkillEvals;