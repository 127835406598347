import React, { Component } from 'react';
class FaqSection extends Component {
    render() {
        return (
            <>
                <section className="section faq-area style-two ptb_100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-7">
                                {/* Section Heading */}
                                <div className="section-heading text-center">
                                    <h2 className="text-capitalize">Frequently Asked Questions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                {/* FAQ Content */}
                                <div className="faq-content">
                                    {/* sApp Accordion */}
                                    <div className="accordion" id="sApp-accordion">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-6">
                                                {/* Single Card */}
                                                <div className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                        <h2 className="mb-0">
                                                            <button className="btn px-0 py-2" type="button">
                                                                How do I sign up for LastRecord?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        Signing up for an account is simple. Just click the button above and you'll get an Organization account in under two minutes. That means there is no need to talk to sales, sign a contract or negotiate terms.
                                                    </div>
                                                </div>
                                                <div className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                        <h2 className="mb-0">
                                                            <button className="btn px-0 py-2" type="button">
                                                                Where is my data hosted?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        We run LastRecord on a world-class cloud computing provider infrastructure using Amazon Web Services (AWS). For our Canadian friends, we have a data center in Toronto, ensuring no cross-border data transfer. Our infrastructure provider has been architected to be one of the most high performing and secure cloud computing environments available on the planet.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {/* Single Card */}
                                                <div className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                        <h2 className="mb-0">
                                                            <button className="btn px-0 py-2" type="button">
                                                                How secure & reliable is my data?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        We take data security very seriously. Our databases are backed up daily, and secured within private networks. Data is also encrypted in transit and at rest with automated failover protections in place. We offer a 99.99% uptime Service Level Agreement (SLA).
                                                    </div>
                                                </div>
                                                <div className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                        <h2 className="mb-0">
                                                            <button className="btn px-0 py-2" type="button">
                                                                How do I contact your support?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        Our help center is open 24/7 365 and is filled with guides and tutorials. If you wish to speak to a human, you are more than welcome to send us an email or hit us up on the chat - which we monitor around the clock (really).‍
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <p className="text-body text-center pt-4 fw-5">Have a different question not covered here? <a href="/contact-page">Please contact us</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-7">
                                {/* Section Heading */}
                                <div className="section-heading text-center mb-0">
                                    <div className="admin media py-4" style={{ borderBottom: 'solid 1px #e5e5e5', alignItems: 'center'}}>
                                        {/* Admin Thumb */}
                                        <div className="admin-thumb avatar-lg">
                                            <img
                                                className="rounded-circle"
                                                src="/img/casey.png"
                                                alt=""
                                            />
                                        </div>
                                        {/* Admin Content */}
                                        <div className="admin-content media-body pl-3 pl-sm-4" style={{ textAlign: 'left'}}>
                                            <h4 className="admin-name mb-2">
                                                About LastRecord
                                            </h4>
                                            <p>
                                                LastRecord was founded in August of 2020 by Casey McIntosh. Casey was a Career Firefighter in Oregon from 2002
                                                - 2016 and then went on to create CrewSense, the leading scheduling software for Public Safety Agencies. After CrewSense was sold in 2018, Casey wanted to create a new software for Fire / EMS agencies - giving back to the industry he remains so passionate about to this day - and LastRecord was born.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FaqSection;