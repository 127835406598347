import React, { Component } from "react";

class ContactForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <div className="contact-box text-center">
        <form
          method="POST"
          name="contact"
          data-netlify="true"
          className="contact-form"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Your Name"
                  required="required"
                  onChange={this.changeHandler}
                  value={this.state.name}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  required="required"
                  onChange={this.changeHandler}
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="orgname"
                  placeholder="Organization Name"
                  required="required"
                  onChange={this.changeHandler}
                  value={this.state.organization}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder="Subject"
                  required="required"
                  onChange={this.changeHandler}
                  value={this.state.subject}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Message"
                  required="required"
                  onChange={this.changeHandler}
                  value={this.state.message}
                />
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-lg btn-block mt-3">
                <span className="text-white pr-3">
                  <i className="fas fa-paper-plane" />
                </span>
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
