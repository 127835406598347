import React, { Component } from 'react';
import Footer from '../FooterSection/Footer';
import Header from '../HeaderSection/Header';
import Breadcrumb from './Breadcrumb';

class Observations extends Component {
    render() {
        return (
            <div className="blog">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header inverse={true} imageData={"/img/logo-white.png"} />
                    <Breadcrumb title="Employee Performance Reviews" subHeading="Moving your Employee Performance Reviews to a cloud hosted software solution provides many benefits for your Organization." blogBg="/img/firemen.jpg" />
                    <section id="blog" className="section blog-area ptb_50">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div>
                                        {/* Single Blog Details */}
                                        <article className="single-blog-details">
                                            {/* Blog Thumb */}
                                            <div className="blog-thumb">
                                                <img src="/img/observation.png" className="blog-featured-image" alt="Probationary Firefighter daily review" />
                                            </div>
                                            {/* Blog Content */}
                                            <div className="blog-content sApp-blog">
                                                {/* Meta Info */}
                                                <div className="meta-info d-flex flex-wrap align-items-center py-2">
                                                    <ul>
                                                        <li className="d-inline-block p-2">By Casey McIntosh</li>
                                                        <li className="d-inline-block p-2">August 3, 2021</li>
                                                    </ul>
                                                </div>
                                                {/* Blog Details */}
                                                <div className="blog-details">
                                                    <h1 className="blog-title py-3" style={{ fontSize: '1.2rem'}}>LastRecord provides a cloud hosted platform for Fire / EMS agency Employee Performance Reviews (FTO / FTEP, DOR's, etc).</h1>
                                                    <p className="d-sm-block mt_20">Employee Performance Reviews / Observation Reports have long been used in the Emergency Services to assess the overall ability of an employee to retain training and knowledge. They are also an invaluable tool in predicting future performance, long after the employee finishes their 'trial service'.</p>
                                                    <p className="d-sm-block mt_20">Most often, these reports are completed at the end of every shift, tour, month or quarter. For Fire Departments in particular, these reviews are typically completed on recruits going through the Fire Academy and continue on after the employee makes his/her way to shift work. Employees are graded on areas such as: Appearance, Grooming, Relationships with peers, Relationships with the public and overall job performance and knowledge.</p>
                                                    <p className="d-sm-block mt_20">As an employee progresses along their probationary or trial-service path, they amass numerous copies of these reviews. Unfortunately, these reviews are typically tucked away in a binder or stored in a folder on the local network. Being able to accurately run reports, or see how an employee is actually progressing, good or bad, is a challenge.</p>
                                                    <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                                                        <p className="d-sm-block">Being able to accurately run reports, or see how an employee is actually progressing, good or bad, is a challenge.</p>
                                                    </blockquote>
                                                    <p className="d-sm-block mt_20">By moving your Employee Performance Reviews / Observation Reports to a digital solution such as LastRecord, you can quickly spot early warning indicators or view progress with the click of a button. Perhaps an employee started off with great 'attitude' scores, but as they became comfortable in their surroundings over time, that attitude began to sour. With trending data and detailed reporting / graphs, you can quickly spot these problems areas early on - before its too late.</p>
                                                    <div className="blog-thumb">
                                                        <img src="/img/observation_progress.png" style={{ marginTop: 20 }} alt="Probationary Firefighter daily review" className="blog-featured-image" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Blog Comments */}
                                            <div className="blog-comments">
                                                {/* Admin */}
                                                <div className="admin media py-4 mt-4">
                                                    {/* Admin Thumb */}
                                                    <div className="admin-thumb avatar-lg">
                                                        <img className="rounded-circle" src="/img/casey.png" alt="" />
                                                    </div>
                                                    {/* Admin Content */}
                                                    <div className="admin-content media-body pl-3 pl-sm-4">
                                                        <h4 className="admin-name mb-2"><a href="/#">Casey McIntosh</a></h4>
                                                        <p>Casey was a Career Firefighter in Oregon from 2002 - 2016. He was also the founder of CrewSense.com and is a passionate supporter of the Emergency Services. He is now the founder of LastRecord.com.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Observations;