import React, { Component } from "react";

class HeroSection extends Component {
  state = {
    data: {},
  };
  render() {
    return (
      <section
        id="home"
        className="section overflow-hidden d-flex align-items-center"
        style={{ background: "#172d48" }}
      >
        <div className="container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 0,
            }}
            className="row hero-bg"
          >
            <div className="col-lg-6">
              <div
                className="welcome-intro"
                style={{
                  color: "rgb(208, 208, 208)",
                  padding: 30,
                  textAlign: "center",
                }}
              >
                <div className="hero-h1" style={{ fontSize: "1.6em" }}>
                  Purpose Built Software for Public Safety
                </div>
                <div
                  style={{
                    color: "rgb(248, 248, 248)",
                    fontSize: "2.7rem",
                    fontWeight: 800,
                    lineHeight: ".8",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Performance Reviews <br /> Task Books <br /> & Skill Competencies
                </div>
                <div className="welcome-bottom">
                  <h3 style={{ marginTop: 20 }} className="trial-link">
                      <span className="highlight-text" style={{ color: '#fff' }}>
                        Any size agency - just $1899 / year!
                      </span>
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div className="col-12">
                <img src="/img/rev2.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroSection;
