import React, { Component } from 'react';

const initData = {
    heading: "Mobile App",
    headingText: "Our TotalEvals by LastRecord mobile app is available as a native app for both iOS and Android devices.",
    headingTexttwo: "This powerful app allows you to perform Live Skill Assessments (skill sheets) & Performance Reviews from anywhere with a data connection; including features like Live Video Recording, e-signatures, and more.",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
}

class Download extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="section overlay-dark ptb_100" style={{ background: '#203b5c'}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                        <h2 className="text-white">{this.state.initData.heading}</h2>
                        <p className="text-white my-3 d-none d-sm-block">{this.state.initData.headingText}</p>
                        <p className="text-white my-3 d-block d-sm-block">{this.state.initData.headingTexttwo}</p>
                        {/* Store Buttons */}
                        <div className="button-group store-buttons d-flex justify-content-center">
                            <a href="https://play.google.com/store/apps/details?id=com.lastrecord.lastrecordmobile&hl=en_US&gl=US">
                            <img src={this.state.initData.playImg} alt="" />
                            </a>
                            <a href="https://apps.apple.com/us/app/lastrecord-mobile/id1545433944#?platform=iphone">
                            <img src={this.state.initData.appleImg} alt="" />
                            </a>
                        </div>
                        <span className="d-inline-block text-white fw-3 font-italic mt-3">{this.state.initData.headingSlug}</span>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Download;