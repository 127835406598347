import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

function Header(props) {
    const { inverse } = props;
    const [isShown, setIsShown] = useState(false);

    return (
        <header className={`navbar ${inverse === true ? ("navbar-white-bg") : ''} navbar-sticky navbar-expand-lg`} style={{ background: '#ffffff' }}>
            <div className="container position-relative">
                <a className="navbar-brand" href="/">
                    <img className="navbar-brand-regular" src="/img/logo.png" alt="LastRecord" style={{ height: 25 }} />
                    <img className="navbar-brand-sticky" src="/img/logo.png" alt="LastRecord" style={{ height: 25 }} />
                </a>
                <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="navbar-inner">
                    {/*  Mobile Menu Toggler */}
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <nav>
                        <ul className="navbar-nav" id="navbar-nav">
                            {/*                                 <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Home
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li>
                                            <a className="dropdown-item" href="/">Homepage-1</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-two">Homepage-2</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-three">Homepage-3</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-four">Homepage-4</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-five">Homepage-5</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/theme-six">Homepage-6</a>
                                        </li>
                                    </ul>
                                </li> */}
                            <li className="nav-item" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                                <HashLink smooth className="nav-link" >Pricing</HashLink>
                                {isShown && (
                                    <>
                                        <div className="pricing-hover-box">
                                            <span className="pricing-header"> Platform Subscription</span>
                                            <span className="highlight-text pricing-cost"> $149 mo / agency</span>
                                            <div style={{ marginTop: 10 }}>
                                                <span className="pricing-item"> <CheckCircleOutlineTwoToneIcon style= {{ fontSize: '1em', color: '#26aa57' }}/> All Modules</span> <br/>
                                                <span className="pricing-item"> <CheckCircleOutlineTwoToneIcon style= {{ fontSize: '1em', color: '#26aa57' }}/> Unlimited Users</span> <br/>
                                                <span className="pricing-item"> <CheckCircleOutlineTwoToneIcon style= {{ fontSize: '1em', color: '#26aa57' }}/> 1-on-1 Support</span> <br/>
                                                <span className="pricing-item"> <CheckCircleOutlineTwoToneIcon style= {{ fontSize: '1em', color: '#26aa57' }}/> Document Conversion</span>
                                            </div>
                                            <a href="https://buy.stripe.com/bIY7wq2B80W21fqaEF" target="_blank"><div className="pricing-cost" style={{ justifyContent: 'center', cursor: 'pointer', display: 'flex', marginTop: 10, padding: 10, background: '#203b5c', color: '#fff'}}> Purchase Now!</div></a>
                                        </div>
                                    </>
                                )}
                            </li>
                            <li className="nav-item">
                                <HashLink smooth className="nav-link" to="/#features">Features</HashLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link trial-text" href="/contact-page">Request a Demo</a>
                            </li>
                            <li className="nav-item">
                                <li className="nav-item">
                                    <a className="nav-link" style={{ fontWeight: '800', background: 'rgb(32, 59, 92)', color: '#fff' }} href="https://app.lastrecord.com">Login</a>
                                </li>

                                {/*                                 <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Pages
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Inner Pages<span className="badge badge-pill badge-warning ml-2">New</span></a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/pricing">Pricing</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/download-page">Download</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/subscribe-page">Newsletter</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/thank-you">Thank you</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/coming-soon">Coming Soon</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/error-page">404</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Blog Pages</a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/blog-two-column">Blog- 2 Column</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-three-column">Blog- 3 Column</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-left-sidebar">Blog- Left Sidebar</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-right-sidebar">Blog- Right Sidebar</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Blog Details</a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/blog-details-left-sidebar">Blog Details- Left Sidebar</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-details-right-sidebar">Blog Details- Right Sidebar</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Accounts<span className="badge badge-pill badge-warning ml-2">New</span></a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/login">Login</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/signup">Signup</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/forgot">Reset Password</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/reviews">Reviews</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/faq">FAQ</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/contact-page">Contact</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item disabled" href="/#">More Coming Soon</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link scroll" href="#screenshots">Screenshots</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link scroll" href="#pricing">Pricing</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link scroll" href="#contact">Contact</a>
                                     */}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;