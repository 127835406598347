import React, { Component } from 'react';

class Breadcrumb extends Component {
    render() {
        const { blogBg, height, noBg } = this.props;

        return (
            <section className='section breadcrumb-area d-flex align-items-center' style={{ backgroundColor: 'rgb(32, 59, 92)', height: height == 'sm' ? 300 : null }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                                <h1 style={{fontSize: '3em', color: '#fff', marginTop: 70}}>{this.props.title}</h1>
                                <h3 className="text-white" style={{fontSize: '1.4em', marginTop: 20 }}>{this.props.subHeading}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Breadcrumb;