import React, { Component } from 'react';
import Footer from './FooterSection/Footer';
import Header from './HeaderSection/Header';

const initData = {
    heading: "Thank you for downloading sApp",
    content: "We are continuously creating new stuff for sApp and trying to keep it up to date with the new technologies that might appear along the development process. We appreciate each of you who downloaded or purchased our theme and we take your suggestions into consideration every time.",
    btnText: "Go to Homepage"
}

class Cookies extends Component {
    state = {
        initData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <div className="inner inner-pages">
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} inverse={true}/>
                    <section id="home" className="section" style={{ background: '#070b12'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12" style={{ marginTop: 110}}>
                                    <div>
                                        <h1 className="text-white">Cookies Policy</h1>
                                        <p className="text-white my-4">
                                        Effective: August 17, 2020
<br/>
Intro
Cookies are small text files that are stored in a computer’s web browser memory. They help website providers with things like understanding how people use a website, remembering a User’s login details, and storing website preferences. This page explains how we use cookies and other similar technologies to help us ensure that our Services function properly, prevent fraud and other harm, and analyze and improve the Services in accordance with our Privacy Policy. Any capitalized term used and not otherwise defined below has the meaning assigned to it in the Privacy Policy.
<br/>
1. How We Use Cookies
<br/>
Cookies play an important role in helping us provide personal, effective and safe Services. We use cookies on our website. We change the cookies periodically as we improve or add to our Services, but we generally use cookies for the following purposes:
<br/>
1.1 To Operate Our Services
<br/>
Some cookies are essential to the operation of our website and Services. We use those cookies in a number of different ways, including:
<br/>
Authentication. We use cookies to remember Your login state so You don’t have to login as You navigate through our site and Your dashboard. For example, when You log into Your LastRecord dashboard account, we use a cookie so that You don’t have to login again as You navigate throughout the website.
<br/>
Fraud Prevention and Detection. Cookies and similar technologies that we deploy through websites and the Services help us learn things about computers and web browsers used to access the Services. This information helps us monitor for and detect potentially harmful or illegal use of our Services.
<br/>
Site Features and Services. We use cookies to remember how You prefer to use our Services so that You don’t have to reconfigure Your settings each time You log into Your account.
<br/>
1.2 To Analyze and Improve Our Services
<br/>
Cookies help us understand how to make our website and Services work better for You. Cookies tell us how people reach our website and our Users’ websites and they give us insights into improvements or enhancements we need to make to our website and Services.
<br/>
1.3 For Better Advertising
<br/>
Cookies can help us provide more effective advertising on our website. For example, we might use a cookie to help prevent You from seeing the same advertisement multiple times or to measure how many times an advertisement is viewed or clicked on.
<br/>
2. How We Use Other Technologies
<br/>
2.1 Pixel tags
<br/>
Pixel tags (also known as web beacons and clear GIFs) may be used in connection with some Services to, among other things, track the actions of Users (such as email recipients), measure the success of our marketing campaigns and compile statistics about usage of the Services and response rates.
<br/>
2.2 Third Party Analytics
<br/>
We use Tawk.to and Google Analytics, which uses cookies and similar technologies, to collect and analyze information about use of the Services and report on activities and trends. This service may also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices on the Google website.
<br/>
3. How To Manage Cookies
<br/>
Your web browser may allow You to change Your cookie preferences, including to delete and disable LastRecord cookies. Please consult the help section of Your web browser or follow the links below to understand Your options, but please note that if You choose to disable the cookies, some features of our website or Services may not operate as intended.
<br/>
Chrome: https://support.google.com/chrome/answer/95647?hl=en
<br/>
Explorer: https://support.microsoft.com/en-us/products/windows?os=windows-10
<br/>
Safari: https://support.apple.com/kb/PH21411
<br/>
Firefox: https://support.mozilla.org/products/firefox/cookies
<br/>
Opera: https://www.opera.com/help/tutorials/security/cookies/
<br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />

            </div>
        );
    }
}

export default Cookies;