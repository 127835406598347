import React, { Component } from 'react';

const initData = {
    heading: "Our Customers Trust Us.",
    headingText: "We believe in building exceptional software at an affordable price, and we've been doing so since 2012. We always put customer satisfaction before anything else.",
}

const data = [
    {
        image: "/img/fd3.png",
        customer: "Jackson County Fire Dist 3"
    },
    {
        image: "/img/mig.png",
        customer: "Murrels Inglet Fire"
    },
    {
        image: "/img/hud.png",
        customer: "Hudson Fire"
    },
    {
        image: "/img/mfr.png",
        customer: "Medford Fire"
    },
    {
        image: "/img/mansfield.png",
        customer: "Mansfield Fire"
    },
    {
        image: "/img/dcfd2.png",
        customer: "Douglas County Fire Dist 2"
    },
    {
        image: "/img/alb.png",
        customer: "Albequeurque Fire"
    },
    {
        image: "/img/clarkcowlitz.png",
        customer: "Clark Cowlitz Fire"
    },
    {
        image: "/img/gpfr.png",
        customer: "Grants Pass Fire"
    },
    {
        image: "/img/jcfd5.png",
        customer: "Jackson County Fire Dist 5"
    },
    {
        image: "/img/napa.png",
        customer: "Napa Fire Dept"
    },
    {
        image: "/img/eng.png",
        customer: "Englewood Fire"
    }
]

class BrandingSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="branding-area ptb_50" style={{ paddingTop: 115 }}>
                <div className="container" style={{ zIndex: 9999}}>
                    <div className="row align-items-center justify-content-center" style={{ textAlign: 'center'}}>
                        <h2 className="mt-2">Fire & EMS <span style={{ background: '#f9f9f9', padding: '0px 5px'}}>Task Books</span> & <span style={{ background: '#f9f9f9', padding: '0px 5px'}}>Performance Reviews</span> in the cloud</h2>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <h1 style={{ fontSize: "1.5rem", maxWidth: "50vw", color: '#747474' }}>
                            LastRecord is software for managing Task Books,
                            Performance Reviews, Competencies & More.
                        </h1>
                    </div>
                    <div className="mt-4 mb-4">
                        <img src="/img/db.png" alt="" style={{ maxWidth: 1000, marginTop: 20 }} />
                    </div>
                    </div>

                    <hr />
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            {/* Section Heading */}
                            <div className="section-heading text-center" style={{ marginBottom: 25 }}>
                                <h2>{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Branding Slider */}
                        <div className="branding-slider owl-carousel op-5">
                            {/* Single Brand */}
                            {this.state.data.map((item, idx) => {
                                return (
                                    <div key={`b_${idx}`} className="single-brand p-3">
                                        <img src={item.image} alt="" className="customer-image" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default BrandingSection;