import React, { Component } from "react";
import ReactPlayer from "react-player";
import Footer from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";
import Breadcrumb from "./Breadcrumb";

class TaskBookSkillSheets extends Component {
  render() {
    return (
      <div className="blog">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header inverse={true} imageData={"/img/logo-white.png"} />
          <Breadcrumb
            noBg={true}
            title="Manage employee Task Books in the Cloud"
            subHeading="Moving your paper Task Books to a cloud hosted software solution provides many benefits for not only your Organization, but your Employees."
            blogBg="/img/firemen.jpg"
          />
          <section id="blog" className="section blog-area ptb_50">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div>
                    {/* Single Blog Details */}
                    <article className="single-blog-details">
                      {/* Blog Thumb */}
                      {/* Blog Thumb */}
                      <div
                        className="blog-thumb"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div>
                          <ReactPlayer
                            url="/img/tb-overview.mp4"
                            width="800px"
                            controls
                            playing
                            light="/img/tb_screenshot.png"
                          />
                        </div>
                      </div>
                      {/* Blog Content */}
                      <div className="blog-content sApp-blog">
                        {/* Meta Info */}
                        <div className="meta-info d-flex flex-wrap align-items-center py-2">
                          <ul>
                            <li className="d-inline-block p-2">
                              By Casey McIntosh
                            </li>
                            <li className="d-inline-block p-2">
                              July 25, 2021
                            </li>
                          </ul>
                        </div>
                        {/* Blog Details */}
                        <div className="blog-details">
                        <h1 className="blog-title py-3" style={{ fontSize: '1.2rem'}}>LastRecord provides a cloud hosted platform for Fire / EMS agency Task Books.</h1>
                          <p className="d-sm-block mt_20">
                            It is a long established belief that most Fire
                            Departments do things, the way it's always been
                            done. There is a famous quote in the Fire Service,
                            made popular by a probably more famous Firefighting
                            movie of the 90's, that states the Fire Service is
                            "150 Years of Tradition Unimpeded by Progress." This
                            applies not only to tools and tactics used on the
                            fire ground, but also on processes used within the
                            firehouse as well. For the purpose of this article,
                            we will explorer a less glamorous, but important
                            aspect of Fire Department career progress - the
                            dreaded Task Book.
                          </p>
                          <p className="d-sm-block mt_20">
                            Task Books are used for a variety of purposes in the
                            Fire Service. These include: Promotional Processes,
                            New Hires / Probationary Employees, State
                            Certifications, National Certifications and more.{" "}
                          </p>
                          <p className="d-sm-block mt_20">
                            When I began my Fire Service career in 2002, I was
                            issued a Task Book to complete within my first 12
                            months of service. This Task Book was nothing more
                            than a few pieces of paper, with a variety of
                            individual items that I needed to complete (with
                            supervisor signatures and time stamps). It was my
                            responsibility to keep it updated, safe and
                            accurate.
                          </p>
                          <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                            <p className="d-sm-block">
                              Task Books are used for a variety of purposes in
                              the Fire Service. These include: Promotional
                              Processes, New Hires / Probationary Employees,
                              State Certifications, National Certifications and
                              more.
                            </p>
                          </blockquote>
                          <p className="d-sm-block mt_20">
                            As I progressed throughout my career, one Task Book
                            would lead to another. Firefighter I led to
                            Firefighter II. Firefighter II led to Fire Apparatus
                            Driver I, Fire Apparatus Driver I led to Fire
                            Officer I - and so on and so forth.
                          </p>
                          <p className="d-sm-block mt_20">
                            Managing a Task Book or Career Progression program
                            in your Agency can be daunting for a Training
                            Officer. Maintaining and updating requirements,
                            distributing Task Books, tracking user progress and
                            obtaining verified signature sign offs all require
                            time and attention to detail by those managing the
                            program. Combine this with the inevitable "lost task
                            book" or water damaged binder, and it can be a real
                            pain to manage.{" "}
                          </p>
                          <p className="d-sm-block mt_20">
                            Moving your Task Books to the cloud can solve many
                            of these problems, and more. By allowing you to
                            easily create, update, assign and maintain Task
                            Books all from one centralized location; you can
                            focus on more important training matters in your
                            Agency. Your employees can view their Task Book
                            progress, and obtain verified Task sign offs all
                            from their mobile device. Records are always backed
                            up and current, and 'versioning' means your assigned
                            Task Books will always be the correct version.{" "}
                          </p>
                          <p className="d-sm-block mt_20">
                            Moving away from paper Task Books and outdated
                            processes can save your Department and your
                            employees a lot of time and headache.
                          </p>
                        </div>
                      </div>
                      {/* Blog Comments */}
                      <div className="blog-comments">
                        {/* Admin */}
                        <div className="admin media py-4 mt-4">
                          {/* Admin Thumb */}
                          <div className="admin-thumb avatar-lg">
                            <img
                              className="rounded-circle"
                              src="/img/casey.png"
                              alt=""
                            />
                          </div>
                          {/* Admin Content */}
                          <div className="admin-content media-body pl-3 pl-sm-4">
                            <h4 className="admin-name mb-2">
                              <a href="/#">Casey McIntosh</a>
                            </h4>
                            <p>
                              Casey was a Career Firefighter in Oregon from 2002
                              - 2016. He was also the founder of CrewSense.com
                              and is a passionate supporter of the Emergency
                              Services. He is now the founder of LastRecord.com.
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default TaskBookSkillSheets;
