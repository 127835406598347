import React, { Component } from 'react';

class PricingSection extends Component {
    render() {
        return (
            <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center mb-0">
                                <h2>LastRecord costs just $149 / month!<br /></h2>
                                <h3 className="mt-4">
                                    No upsells, no hidden fees, no contracts.
                                </h3>
                                <div style={{ display: 'flex', justifyContent: 'center' }}><a href="https://buy.stripe.com/bIY7wq2B80W21fqaEF" target="_blank"><div className="pricing-cost" style={{ marginTop: 20, justifyContent: 'center', cursor: 'pointer', display: 'flex', width: 200, padding: 10, background: '#203b5c', color: '#fff' }}> Purchase Now!</div></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PricingSection;