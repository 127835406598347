import React, { Component } from 'react';

class FooterSection extends Component {
    render() {
        return (
            <div>
                <div/>
                <footer>
                    {/* Footer Bottom */}
                    <div>
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-sm-between py-4">
                                <div><a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Use</a> | <a href="/cookies">Cookie Policy</a> </div>
                                <div>© {new Date().getFullYear()} LastRecord.com & LastRecord, LLC. All rights reserved.</div>
                                <div className="mt-3">LastRecord, LLC is located at PO BOX 1452, Merlin, OR 97532. <br/>All trademarks, service marks and logos appearing on the site are the property of their respective owners.</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;