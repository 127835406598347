import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";

class DiscoverSection extends Component {
  componentDidMount() {}
  render() {
    return (
      <section className="section discover-area overflow-hidden" id="features">
        <div className="container" id="performance-reviews">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/perf-reviews.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              {/* Discover Text */}
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Intelligent Performance Reviews</h2>
                {/* List Box */}
                <div className="list-box media">
                  <span className="media-body">
                    Ditch the outdated paper forms and excel spreadsheets - with
                    LastRecord, it is easy to manage an Observation Reporting /
                    Performance Evaluation program. Effortlessly build, maintain
                    and complete Daily Observations (DORs), Tourly Observations
                    (TORs), FTO, Annual Evals and more. Search, View and Include
                    relevant documents like{" "}
                    <HashLink
                      smooth
                      to="/#competencies"
                      style={{ fontWeight: 800, textDecoration: "underline" }}
                    >
                      Skill Competencies{" "}
                    </HashLink>
                    ,{" "}
                    <HashLink
                      smooth
                      to="/#tb"
                      style={{ fontWeight: 800, textDecoration: "underline" }}
                    >
                      Task Book
                    </HashLink>{" "}
                    Task completions and more in employee Performance Reviews.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="tb">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-2">
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/tb.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Text */}
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Digital Task Books</h2>
                {/* List Box */}
                <div className="list-box media">
                  <span className="media-body">
                    Build, Assign & Complete employee position Task Books all
                    from the cloud. Our intuitive platform allows you to easily
                    create custom Task Books for promotions, trial service,
                    certifications and more. Effortlessly obtain signature
                    sign-off's or digital verifications all from the cloud.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="competencies">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/skill-ass.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-2 order-lg-2">
              {/* Discover Text */}
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Skill Competencies</h2>
                {/* List Box */}
                <div className="list-box media">
                  <span className="media-body">
                    Simply keeping paper records of your employees or newly
                    promoted staff skill checkoff's and task performances is not
                    enough. With LastRecord, you will always have detailed
                    record keeping; including live video recordings of all
                    Individual & Group Task Performances. Whether you're on the
                    Drill Ground or in the field - the LastRecord mobile
                    evaluator app goes anywhere.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="container blue-bg">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/training.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Training Records</h2>
                <div className="list-box media">
                  <span className="media-body">
                    With TotalEvals, you can easily manage and track your
                    Employee Training Records. Track date of training,
                    attendees, location, instructor(s), categories, topic and
                    more. Detailed reporting functionality allow you to perform
                    year end and ISO reports with ease.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-2">
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/reporting.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-1">
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Reporting / Analytics</h2>
                <div className="list-box media">
                  <span className="media-body">
                    Pulling and analyzing important Organizational data is a
                    breeze with TotalEvals. Quickly run reports for ISO
                    purposes; end of year statistics; training records and more.
                    Effortlessly see employee training hours by category, topic,
                    and overdue assignments. View TaskBook progress, SkillSheet
                    completion, certifications and more.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container blue-bg">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/trialservice.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Trial Service Management</h2>
                <div className="list-box media">
                  <span className="media-body">
                    From the moment a new hire starts trial service, to the day
                    they successfully complete it - tracking their important
                    milestones is made easy with TotalEvals. Easily add
                    milestones and mark as complete at all important points
                    throughout their journey.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: 40 }}>
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-2">
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src="/img/data.png" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-1">
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4">Data Imports</h2>
                <div className="list-box media">
                  <span className="media-body">
                    Years of important data stuck in other vendor / solutions?
                    No problem. Let us import your historical Training Records;
                    Evaluations; Performance reviews and more to help get you up
                    and running from day one.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
      </section>
    );
  }
}

export default DiscoverSection;
